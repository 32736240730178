@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.float-right {
  float: right;
}

.search__logo {
  max-height: 100vh;
  max-width: 100%;
  height: auto;
  width: auto;
}

.search-form {
  .search-btn {
    right: .25rem;
    top: .2rem;
    bottom: .2rem;
    font-size: .8rem;
    color: $border;

    &:hover {
      color: darken(white, 20%);
    }
  }

  &__input {
    font-size: .8rem;

    @include mediaQuery($max, $lg) {
      font-size: 16px;
    }
  }
}

.search-results__form input {
  font-size: 1.75rem;
}

.dividers > * + * {
  border-top: 1px solid darken(white, 70%);
}

.search-results {

  &__sort-header {
    font-size: .9rem;
    background: fade-out($dark-grey, .85);
  }

  .list-group-item {
    background: transparent;
    color: darken(white, 12%);
    font-size: .9rem;

    @include mediaQuery($min, $xl) {
      font-weight: bold;
    }

    &:hover {
      background: fade-out(black, .75);
    }
  }

  // a {
  //   color: white;
  //   color: darken(white, 8%);
  // }

  .seen {
    color: fade-out(white, .5);
    font-weight: 400 !important;
  }

  .search__result_title {
    text-decoration: none;

    @include mediaQuery($max, $xl) {
      font-size: 1.05rem;
      font-weight: bold;
      // display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }

    // &.seen {
    //   color: fade-out(white, .5);
    //   font-weight: 400 !important;
    // }
  }

  .fa-envelope-open {
    color: darken(white, 70%);
  }

  td {
    max-width: 300px;
    padding: 4px;
  }

  th {
    padding: 4px;
    font-size: 1.2em;
    font-weight: 700;
    border-bottom: 1px solid white;

    a {
      text-decoration: none;
    }
  }
}

.doc-view {
  position: fixed;
  height: calc(100vh - 38px);
  margin-right: -80%;
  width: 60%;
  right: 0%;
  top: 38px;
  transition: right 200ms ease-in-out;
  z-index: 20;

  &.open {
    right: 80%;
    z-index: 8002;
    border: 2px solid darken(white, 20%);
  }

  .close {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    background: rgb(var(--bs-light-rgb));
    padding: 0 2px;
    transform: scale(2.15);
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .doc-view {
    width: 95%;
    margin-right: -100%;

    &.open {
      right: 100%
    }
  }
}

.filter-header {
  font-size: .8rem;
  opacity: .65;
}

.portfolio-filters__btn {
  small {
    display: inline-block;
    whitespace: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
}

.saved-searches {
  a:hover {
    color: darken(white, 10%);
  }

  // border-top: 1px solid darken(white, 20%);
  // border-bottom: 1px solid darken(white, 20%);

}

.modal-dialog {
  color: lighten(black, 20%);
}

.dropdown-menu {
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
